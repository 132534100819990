import {createSlice} from '@reduxjs/toolkit';
import moment from "moment/moment";

const initialState = {
    basicDetails: {
        name: '',
        status: '',
        billingType: '',
        distributorPercentage: '',
        tdsPercentage: '',
        agrPercentage: '',
        taxNo: '',
        taxAccount: '',
        remark: '',
        note: '',
        commissionRuleCode: '',
        commissionPeriodicity: '',
        autoRefundEnabled: '',
        autoRefundCommissionRuleCode: '',
        activeFrom: null,
        distributorId: '',
        legalOfficeId: '',
        supportOfficeId: '',
        zoneGroupCode: '',
    },
    addresses: [],
    contacts: []
};

export const ZoneSlice = createSlice({
    name: 'zone',
    initialState,
    reducers: {
        setDetails: (state, action) => {
            const data = action.payload;
            state.addresses = data.addresses || [] ;
            state.contacts = data.contacts || [];
            state.basicDetails = {
                name: data.name,
                status: data.status,
                zoneExternalId: data.zoneExternalId,
                billingType: data.billingType,
                distributorPercentage: data.distributorPercentage,
                tdsPercentage: data.tdsPercentage,
                agrPercentage: data.agrPercentage,
                taxNo: data.taxNo,
                taxAccount: data.taxAccount,
                remark: data.remark,
                note: data.note,
                activeFrom: data.activeFrom ? moment(data.activeFrom).format('YYYY-MM-DD') : null,
                distributorId: data.distributor?.id,
                legalOfficeId: data.legalOffice?.id,
                supportOfficeId: data.supportOffice?.id,
                zoneGroupCode: data.zoneGroupCode,
                commissionRuleCode: data.commissionRuleCode,
                commissionPeriodicity: data.commissionPeriodicity,
                autoRefundEnabled: data.autoRefundEnabled,
                autoRefundCommissionRuleCode: data.autoRefundCommissionRuleCode,
            };
        },
        reset: () => initialState,
        setBasicDetails: (state, action) => {
            state.basicDetails = action.payload;
        },
        addAddress: (state, action) => {
            state.addresses = [...state.addresses, action.payload];
        },
        addContact: (state, action) => {
            state.contacts = [...state.contacts, action.payload];
        },
        updateAddress: (state, action) => {
            const index = state.addresses.findIndex(item => item.id === action.payload.id)
            state.addresses[index] = action.payload
        },
        updateContact: (state, action) => {
            const index = state.contacts.findIndex(item => item.id === action.payload.id)
            state.contacts[index] = action.payload
        },
        removeAddress: (state, action) => {
            const index = state.addresses.findIndex(item => item.id === action.payload)
            state.addresses.splice(index, 1)
        },
        removeContact: (state, action) => {
            state.contacts = state.contacts.filter(item => item.id !== action.payload)
        },
    },
});

export const zoneActions = ZoneSlice.actions;
export default ZoneSlice.reducer;
