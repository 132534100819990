import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    companyChanged: false,
    companies: []
};

export const CompanyListSlice = createSlice({
    name: 'companyList',
    initialState,
    reducers: {
        setCompanyList: (state, action) => {
            state.companies = action.payload;
            state.companyChanged = false;
        },
        setCompanyChanged: (state) => {
            state.companyChanged = true;
        },
        reset: () => initialState,
    },
});

export const companyListActions = CompanyListSlice.actions;
export default CompanyListSlice.reducer;
