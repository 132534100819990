import { redirect } from 'react-router-dom';

export const setToken = (token) => localStorage.setItem("ibill_access_token", token);

export const getToken = () => localStorage.getItem("ibill_access_token");

export const clearToken = () => localStorage.removeItem("ibill_access_token");

// eslint-disable-next-line consistent-return
export const checkAuthLoader = () => {
    const token = getToken();
    if(!token) {
        redirect("/login");
    }
}

const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';

const atob = (input = '') => {
    const str = input.replace(/[=]+$/, '');
    let output = '';
    if (str.length % 4 === 1) {
        throw new Error("'atob' failed: The string to be decoded is not correctly encoded.");
    }
    for (
        let bc = 0, bs = 0, buffer, i = 0;
        // eslint-disable-next-line no-cond-assign
        (buffer = str.charAt(i++));
        // eslint-disable-next-line no-bitwise,no-cond-assign
        ~buffer && ((bs = bc % 4 ? bs * 64 + buffer : buffer), bc++ % 4)
            // eslint-disable-next-line no-bitwise
            ? (output += String.fromCharCode(255 & (bs >> ((-2 * bc) & 6))))
            : 0
    ) {
        buffer = chars.indexOf(buffer);
    }
    return output;
};

export const parseJwt = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(c  => {
                return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
            })
            .join(''),
    );

    return JSON.parse(jsonPayload);
}
