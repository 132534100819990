import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    accessToken: '',
    issuer: '',
    fullName: '',
    scope: '',
    mobile: '',
    email: '',
    username: '',
    tenantCode: '',
    exp: '',
};

export const AuthSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        reset: () => initialState,
        setAuthDetails: (state, action) => {
            const data = action.payload;
            state.accessToken = data.accessToken;
            state.issuer = data.issuer;
            state.fullName = data.fullName;
            state.scope = data.scope;
            state.mobile = data.mobile;
            state.email = data.email;
            state.username = data.username;
            state.tenantCode = data.tenantCode;
            state.exp = data.exp;
        },
    },
});

export const authActions = AuthSlice.actions;
export default AuthSlice.reducer;
