import React, {Suspense, useEffect} from 'react';
import {createTheme} from "react-data-table-component";
import {useRoutes} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import Themeroutes from './routes/Router';
import ThemeSelector from './layouts/theme/ThemeSelector';
import Loader from './layouts/loader/Loader';
import {companyListActions} from "./store/company/CompanyListSlice";
import {authActions} from "./store/auth/AuthSlice";
import {clearToken} from "./oauth/oauthUtils";

const App = () => {
    const routing = useRoutes(Themeroutes);
    const direction = useSelector((state) => state.customizer.isRTL);
    const isMode = useSelector((state) => state.customizer.isDark);

    createTheme('iLight', {
        text: {
            primary: 'rgba(0, 0, 0, 0.87)',
            secondary: 'rgba(0, 0, 0, 0.54)',
            disabled: 'rgba(0, 0, 0, 0.38)',
        },
        background: {
            default: '#F9F9F9',
        },
        context: {
            background: '#e3f2fd',
            text: 'rgba(0, 0, 0, 0.87)',
        },
        divider: {
            default: 'rgba(0,0,0,.20)',
        },
        button: {
            default: 'rgba(0,0,0,.54)',
            focus: 'rgba(0,0,0,.12)',
            hover: 'rgba(0,0,0,.12)',
            disabled: 'rgba(0, 0, 0, .18)',
        },
        selected: {
            default: '#e3f2fd',
            text: 'rgba(0, 0, 0, 0.87)',
        },
        highlightOnHover: {
            default: '#EEEEEE',
            text: 'rgba(0, 0, 0, 0.87)',
        },
        striped: {
            default: '#EEEFEE',
            text: 'rgba(0, 0, 0, 0.87)',
        },
    },);

    createTheme('iDark', {
        text: {
            primary: '#FFFFFF',
            secondary: 'rgba(0, 0, 0, 0.54)',
            disabled: 'rgba(0, 0, 0, 0.38)',
        },
        background: {
            default: '#1D2B39',
        },
        context: {
            background: '#e3f2fd',
            text: 'rgba(0, 0, 0, 0.87)',
        },
        divider: {
            default: '#FFFFFF',
        },
        button: {
            default: 'rgba(0,0,0,.54)',
            focus: 'rgba(0,0,0,.12)',
            hover: 'rgba(0,0,0,.12)',
            disabled: 'rgba(0, 0, 0, .18)',
        },
        selected: {
            default: '#e3f2fd',
            text: 'rgba(0, 0, 0, 0.87)',
        },
        highlightOnHover: {
            default: '#EEEEEE',
            text: 'rgba(0, 0, 0, 0.87)',
        },
        striped: {
            default: '#303a45',
            text: '#FFFFFF',
        }
    });

    const  dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(companyListActions.reset());
            dispatch(authActions.reset());
            clearToken();
        }
    }, []);

    return (
        <Suspense fallback={<Loader/>}>
            <div
                className={`${direction ? 'rtl' : 'ltr'} ${isMode ? 'dark' : ''}`}
                dir={direction ? 'rtl' : 'ltr'}
            >
                <ThemeSelector/>
                {routing}
            </div>
        </Suspense>
    );
};

export default App;
