import { lazy } from "react";
import { Navigate } from "react-router-dom";
import Loadable from "../layouts/loader/Loadable";
import OAuthPopup from "../oauth/OAuth2Popup";
/****Layouts*****/
const FullLayout = Loadable(lazy(() => import("../layouts/FullLayout")));
const BlankLayout = Loadable(lazy(() => import("../layouts/BlankLayout")));
/***** Pages ****/

const Company = Loadable(lazy(() => import("../components/company/Company")));
const CompanyDetails = Loadable(lazy(() => import("../components/company/CompanyDetails")));
const CompanyList = Loadable(lazy(() => import("../components/company/CompanyList")));

const DistributorList = Loadable(lazy(() => import("../components/distributor/DistributorList")));
const DistributorDetails = Loadable(lazy(() => import("../components/distributor/DistributorDetails")));
const Distributor = Loadable(lazy(() => import("../components/distributor/Distributor")));

const ServerGroupList = Loadable(lazy(() => import("../components/serverGroup/ServerGroupList")));
const ServerGroupDetails = Loadable(lazy(() => import("../components/serverGroup/ServerGroupDetails")));
const ServerGroup = Loadable(lazy(() => import("../components/serverGroup/ServerGroup")));

const ZoneGroupList = Loadable(lazy(() => import("../components/zoneGroup/ZoneGroupList")));
const ZoneGroupDetails = Loadable(lazy(() => import("../components/zoneGroup/ZoneGroupDetails")));
const ZoneGroup = Loadable(lazy(() => import("../components/zoneGroup/ZoneGroup")));

const ZoneList = Loadable(lazy(() => import("../components/zone/ZoneList")));
const ZoneDetails = Loadable(lazy(() => import("../components/zone/ZoneDetails")));
const Zone = Loadable(lazy(() => import("../components/zone/Zone")));
const ZoneStatement = Loadable(lazy(() => import("../components/zone/ZoneStatement")));
const ZoneCommission = Loadable(lazy(() => import("../components/zone/ZoneCommission")));
const ZoneRenew = Loadable(lazy(() => import("../components/zone/ZoneRenew")));
const ZoneBalance = Loadable(lazy(() => import("../components/zone/ZoneBalance")));

const LegalOfficeList = Loadable(lazy(() => import("../components/legalOffice/LegalOfficeList")));
const LegalOfficeDetails = Loadable(lazy(() => import("../components/legalOffice/LegalOfficeDetails")));
const LegalOffice = Loadable(lazy(() => import("../components/legalOffice/LegalOffice")));

const SupportOfficeList = Loadable(lazy(() => import("../components/supportOffice/SupportOfficeList")));
const SupportOfficeDetails = Loadable(lazy(() => import("../components/supportOffice/SupportOfficeDetails")));
const SupportOffice = Loadable(lazy(() => import("../components/supportOffice/SupportOffice")));

const ServerList = Loadable(lazy(() => import("../components/server/ServerList")));
const ServerDetails = Loadable(lazy(() => import("../components/server/ServerDetails")));
const Server = Loadable(lazy(() => import("../components/server/Server")));

const PackageTypeList = Loadable(lazy(() => import("../components/packageType/PackageTypeList")));
const PackageTypeDetails = Loadable(lazy(() => import("../components/packageType/PackageTypeDetails")));
const PackageType = Loadable(lazy(() => import("../components/packageType/PackageType")));

const Login = Loadable(lazy(() => import('../views/auth/Login')));

/***** Auth Pages ****/
const Error = Loadable(lazy(() => import("../views/auth/Error")));
/*****Routes******/

const ThemeRoutes = [
  {
    path: "/",
    element: <FullLayout />,
    errorElement: <Error/>,
    children: [
      { path: '*', element: <Navigate to="/error" /> },
      { path: '/', element: <Navigate to="/company/list" /> },
      { path: "/company",
        children: [
          { path: '', element: <Navigate to="/company/list" /> },
          { path: 'add', element: <Company /> },
          { path: 'edit/:id', element: <Company /> },
          { path: 'list', element: <CompanyList /> },
          { path: 'details/:id', element: <CompanyDetails /> },
          { path: '*', element: <Navigate to="/company/list" /> },
        ]
      },
      { path: "/distributor",
        children: [
          { path: '', element: <Navigate to="/distributor/list" /> },
          { path: 'add', element: <Distributor /> },
          { path: 'edit/:id', element: <Distributor /> },
          { path: 'list', element: <DistributorList /> },
          { path: 'details/:id', element: <DistributorDetails /> },
          { path: '*', element: <Navigate to="/distributor/list" /> },
        ]
      },
      { path: "/serverGroup",
        children: [
          { path: '', element: <Navigate to="/serverGroup/list" /> },
          { path: 'add', element: <ServerGroup /> },
          { path: 'edit/:id', element: <ServerGroup /> },
          { path: 'list', element: <ServerGroupList /> },
          { path: 'details/:id', element: <ServerGroupDetails /> },
          { path: '*', element: <Navigate to="/serverGroup/list" /> },
        ]
      },
      { path: "/zoneGroup",
        children: [
          { path: '', element: <Navigate to="/zoneGroup/list" /> },
          { path: 'add', element: <ZoneGroup /> },
          { path: 'edit/:id', element: <ZoneGroup /> },
          { path: 'list', element: <ZoneGroupList /> },
          { path: 'details/:id', element: <ZoneGroupDetails /> },
          { path: '*', element: <Navigate to="/zoneGroup/list" /> },
        ]
      },
      { path: "/zone",
        children: [
          { path: '', element: <Navigate to="/zone/list" /> },
          { path: 'add', element: <Zone /> },
          { path: 'statement', element: <ZoneStatement /> },
          { path: 'renew', element: <ZoneRenew /> },
          { path: 'commission', element: <ZoneCommission /> },
          { path: 'balance', element: <ZoneBalance /> },
          { path: 'edit/:id', element: <Zone /> },
          { path: 'list', element: <ZoneList /> },
          { path: 'details/:id', element: <ZoneDetails /> },
          { path: '*', element: <Navigate to="/zone/list" /> },
        ]
      },
      { path: "/legalOffice",
        children: [
          { path: '', element: <Navigate to="/legalOffice/list" /> },
          { path: 'add', element: <LegalOffice /> },
          { path: 'edit/:id', element: <LegalOffice /> },
          { path: 'list', element: <LegalOfficeList /> },
          { path: 'details/:id', element: <LegalOfficeDetails /> },
          { path: '*', element: <Navigate to="/legalOffice/list" /> },
        ]
      },
      { path: "/supportOffice",
        children: [
          { path: '', element: <Navigate to="/supportOffice/list" /> },
          { path: 'add', element: <SupportOffice /> },
          { path: 'edit/:id', element: <SupportOffice /> },
          { path: 'list', element: <SupportOfficeList /> },
          { path: 'details/:id', element: <SupportOfficeDetails /> },
          { path: '*', element: <Navigate to="/supportOffice/list" /> },
        ]
      },
      { path: "/server",
        children: [
          { path: '', element: <Navigate to="/server/list" /> },
          { path: 'add', element: <Server /> },
          { path: 'edit/:id', element: <Server /> },
          { path: 'list', element: <ServerList /> },
          { path: 'details/:id', element: <ServerDetails /> },
          { path: '*', element: <Navigate to="/server/list" /> },
        ]
      },
      { path: "/packageType",
        children: [
          { path: '', element: <Navigate to="/zoneGroup/list" /> },
          { path: 'add', element: <PackageType /> },
          { path: 'edit/:id', element: <PackageType /> },
          { path: 'list', element: <PackageTypeList /> },
          { path: 'details/:id', element: <PackageTypeDetails /> },
          { path: '*', element: <Navigate to="/packageType/list" /> },
        ]
      },
    ],
  },
  { path: "/callback", element: <OAuthPopup /> },
  {
    path: "/auth",
    element: <BlankLayout />,
    children: [
      { path: "404", element: <Error /> },
      { path: "*", element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: "/login",
    element: <Login />
  },
  {
    path: "/error",
    element: <Error />
  },
];

export default ThemeRoutes;
