import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    basicDetails: {
        name: '',
        status: '',
        privacyPolicyUrl: '',
        contactUsUrl: '',
        invoiceSequenceRegex: '',
        remark: '',
        note: ''
    },
    addresses: [],
    contacts: []
};

export const CompanySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        setCompanyDetails: (state, action) => {
            const data = action.payload;
            state.addresses = data.addresses;
            state.contacts = data.contacts;
            state.basicDetails = {
                name: data.name,
                status: data.status,
                privacyPolicyUrl: data.privacyPolicyUrl,
                contactUsUrl: data.contactUsUrl,
                invoiceSequenceRegex: data.invoiceSequenceRegex,
                remark: data.remark,
                note: data.note,
            };
        },
        reset: () => initialState,
        setBasicDetails: (state, action) => {
            state.basicDetails = action.payload;
        },
        addAddress: (state, action) => {
            state.addresses = [...state.addresses, action.payload];
        },
        addContact: (state, action) => {
            state.contacts = [...state.contacts, action.payload];
        },
        updateAddress: (state, action) => {
            const index = state.addresses.findIndex(item => item.id === action.payload.id)
            state.addresses[index] = action.payload
        },
        updateContact: (state, action) => {
            const index = state.contacts.findIndex(item => item.id === action.payload.id)
            state.contacts[index] = action.payload
        },
        removeAddress: (state, action) => {
            const index = state.addresses.findIndex(item => item.id === action.payload)
            state.addresses.splice(index, 1)
        },
        removeContact: (state, action) => {
            state.contacts = state.contacts.filter(item => item.id !== action.payload)
        },
    },
});

export const companyActions = CompanySlice.actions;
export default CompanySlice.reducer;
