import { configureStore } from '@reduxjs/toolkit';

import CustomizerReducer from './customizer/CustomizerSlice';
import CompanyReducer from './company/CompanySlice';
import CompanyListReducer from './company/CompanyListSlice';
import DistributorReducer from './distributor/DistributorSlice';
import LegalOfficeReducer from './legalOffice/LegalOfficeSlice';
import SupportOfficeReducer from './supportOffice/SupportOfficeSlice';
import ZoneReducer from './zone/ZoneSlice';
import AuthReducer from './auth/AuthSlice';
import ModalReducer from './modal/ModalSlice';

export const store = configureStore({
  reducer: {
    customizer: CustomizerReducer,
    company: CompanyReducer,
    distributor: DistributorReducer,
    companyList: CompanyListReducer,
    auth: AuthReducer,
    legalOffice: LegalOfficeReducer,
    supportOffice: SupportOfficeReducer,
    zone: ZoneReducer,
    modal: ModalReducer,
  },
});

export default store;
