import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    basicDetails: {
        name: '',
        status: '',
        taxNo: '',
        taxAccount: '',
        remark: '',
        note: '',
        companyId: '',
    },
    addresses: [],
    contacts: []
};

export const SupportOfficeSlice = createSlice({
    name: 'legalOffice',
    initialState,
    reducers: {
        setAllDetails: (state, action) => {
            const data = action.payload;
            state.addresses = data.addresses;
            state.contacts = data.contacts;
            state.basicDetails = {
                name: data.name,
                status: data.status,
                taxNo: data.taxNo,
                taxAccount: data.taxAccount,
                companyId: data.company.id,
                remark: data.remark,
                note: data.note,
            };
        },
        reset: () => initialState,
        setBasicDetails: (state, action) => {
            state.basicDetails = action.payload;
        },
        addAddress: (state, action) => {
            state.addresses = [...state.addresses, action.payload];
        },
        addContact: (state, action) => {
            state.contacts = [...state.contacts, action.payload];
        },
        updateAddress: (state, action) => {
            const index = state.addresses.findIndex(item => item.id === action.payload.id)
            state.addresses[index] = action.payload
        },
        updateContact: (state, action) => {
            const index = state.contacts.findIndex(item => item.id === action.payload.id)
            state.contacts[index] = action.payload
        },
        removeAddress: (state, action) => {
            const index = state.addresses.findIndex(item => item.id === action.payload)
            state.addresses.splice(index, 1)
        },
        removeContact: (state, action) => {
            state.contacts = state.contacts.filter(item => item.id !== action.payload)
        },
    },
});

export const supportOfficeActions = SupportOfficeSlice.actions;
export default SupportOfficeSlice.reducer;
