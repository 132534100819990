import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    visible: false
};

export const ModalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        toggle: (state) => {state.visible = !state.visible}
    },
});

export const modalActions = ModalSlice.actions;
export default ModalSlice.reducer;
