import { useEffect } from 'react';
import Loader from "../layouts/loader/Loader";

const queryToObject = (query) => {
    const parameters = new URLSearchParams(query);
    return Object.fromEntries(parameters.entries());
};

const OAUTH_STATE_KEY = 'react-use-oauth2-state-key';
const OAUTH_RESPONSE = 'react-use-oauth2-response';

const checkState = (receivedState) => {
    const state = sessionStorage.getItem(OAUTH_STATE_KEY);
    return state === receivedState;
};

const OAuthPopup = (props) => {
    const {
        Component = (
            <Loader/>
        ),
    } = props;

    // On mount
    useEffect(() => {
        const payload = {
            ...queryToObject(window.location.search.split('?')[1]),
            ...queryToObject(window.location.hash.split('#')[1]),
        };
        const state = payload?.state;
        const error = payload?.error;

        if (!window.opener) {
            throw new Error('No window opener');
        }

        if (error) {
            window.opener.postMessage({
                type: OAUTH_RESPONSE,
                error: decodeURI(error) || 'OAuth error: An error has occurred.',
            });
        } else if (state && checkState(state)) {
            window.opener.postMessage({
                type: OAUTH_RESPONSE,
                payload,
            });
        } else {
            window.opener.postMessage({
                type: OAUTH_RESPONSE,
                error: 'OAuth error: State mismatch.',
            });
        }
    }, []);

    return Component;
};

export default OAuthPopup;